import { Form } from "react-final-form";
import { useNavigate, useLocation } from "react-router-dom";
import React from "react";

import { FORM_ERROR } from "final-form";
import InputField from "components/form/TextInput";
import { required } from "utils/validations";
import clsx from "clsx";
import { mixpanel } from "utils/mixpanel";

interface FormValues {
  dateOfBirthMonth: string;
  dateOfBirthDay: string;
  dateOfBirthYear: string;
}

const DateOfBirthForm: React.FC = () => {
  let navigate = useNavigate();
  const { state } = useLocation();

  mixpanel.track("form-page-viewed", {
    "form-name": "date-of-birth",
  });

  return (
    <Form
      initialValues={state}
      onSubmit={async (values: FormValues) => {
        const newState = {
          ...state,
          ...values,
        };
        // Form submission logic here
        console.log(newState);
        navigate("/hahs/address", { state: newState });
      }}
      validate={(values: FormValues) => {
        const errors: Partial<FormValues> = {};

        const monthError = required(values.dateOfBirthMonth);
        errors.dateOfBirthMonth = monthError;

        const dayError = required(values.dateOfBirthDay);
        errors.dateOfBirthDay = dayError;

        const yearError = required(values.dateOfBirthYear);
        errors.dateOfBirthYear = yearError;

        return errors;
      }}
      render={({ handleSubmit, submitError, valid }) => (
        <div className="m-8 overflow-clip rounded-3xl bg-white">
          <div className="flex flex-col">
            <div className="flex flex-col content-center justify-center gap-1 bg-white p-10">
              <h1 className="text-caringwireDarkBlue mb-2 text-center text-3xl font-bold">
                Date of Birth
              </h1>
              <p className="text-caringwireDarkBlue mb-2 text-center text-xl font-light">
                This is required to establish access to on-demand Telehealth and
                TeleTherapy. Your personal information is encrypted, secured and
                protected by HIPPA.
              </p>
            </div>
            <div className="p-8">
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <InputField
                  name="dateOfBirthMonth"
                  title="MM"
                  placeholder="MM *"
                  type="text"
                  maxLength={2}
                  format={(value: string) => {
                    // only allow 01-12
                    const month = value?.replace(/\D/g, "");
                    if (month && parseInt(month) > 12) {
                      return "12";
                    } else {
                      return month;
                    }
                  }}
                  autoComplete="bday-month"
                  required
                />
                <InputField
                  name="dateOfBirthDay"
                  title="DD"
                  placeholder="DD *"
                  type="text"
                  maxLength={2}
                  format={(value: string) => {
                    // only allow 01-31
                    const day = value?.replace(/\D/g, "");
                    if (day && parseInt(day) > 31) {
                      return "31";
                    } else {
                      return day;
                    }
                  }}
                  autoComplete="bday-day"
                  required
                />
                <InputField
                  name="dateOfBirthYear"
                  title="YYYY"
                  placeholder="YYYY *"
                  type="text"
                  maxLength={4}
                  format={(value: string) => value?.replace(/\D/g, "")}
                  autoComplete="bday-year"
                  required
                />

                {submitError && (
                  <div className="text-red-500">{FORM_ERROR}</div>
                )}
                <div className="mt-4 flex items-center justify-start">
                  <button
                    onClick={() => {
                      navigate("/hahs", { state: state });
                    }}
                    className="bg-caringwire mr-8 inline-flex items-center px-4 py-2 font-bold text-white hover:bg-blue-700"
                  >
                    <span>←</span>
                  </button>
                  <button
                    type="submit"
                    className={clsx(
                      "bg-caringwire inline-flex w-20 items-center justify-center px-4 py-2 font-bold text-white hover:bg-blue-700",
                      !valid && "opacity-20"
                    )}
                  >
                    <span>OK</span>
                  </button>
                  <span
                    className={clsx("ml-2 text-xs", !valid && "opacity-20")}
                  >
                    ⏎ press <span className="font-bold">Enter</span>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default DateOfBirthForm;
