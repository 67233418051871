import { Form } from "react-final-form";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";

import { FORM_ERROR } from "final-form";
import PhoneNumberInput from "components/form/PhoneNumberInput";
import InputField from "components/form/TextInput";
import { required, email } from "utils/validations";
import clsx from "clsx";
import { mixpanel } from "utils/mixpanel";
import { toast } from "react-toastify";
import LoadingSpinner from "components/form/LoadingSpinner";

interface FormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  gender: string;
}

const InfoForm: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();

  const params = new URLSearchParams(searchParams);
  const redemptionCode = params.get("redemption_code");
  const [loading, setLoading] = useState(true);
  const [invalidCodeMessage, setInvalidCodeMessage] = useState("");

  const isValidRedemptionCode = useCallback(
    async (redemptionCode: string): Promise<boolean> => {
      if (!redemptionCode) {
        toast.error("Redemption code not found");
        console.error("Redemption code not found");
        setInvalidCodeMessage("Redemption code not found");
        setLoading(false);
        return false;
      }
      try {
        const response = await fetch(
          `${process.env.CORE_API_URL}/users/validate-redemption-code?redemptionCode=${redemptionCode}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const resultJSON = await response.json();

        if (!resultJSON.success) {
          toast.error(
            resultJSON.message || "Failed to validate redemption code"
          );
          console.error(
            resultJSON.message || "Failed to validate redemption code"
          );
          setInvalidCodeMessage(
            resultJSON.message || "Failed to validate redemption code"
          );
          setLoading(false);
          return false;
        } else {
          setLoading(false);
          return true;
        }
      } catch (error) {
        toast.error("Error while validating redemption code");
        console.error(error);
        setInvalidCodeMessage("Error while validating redemption code");
        setLoading(false);
        return false;
      }
    },
    []
  );

  useEffect(() => {
    isValidRedemptionCode(redemptionCode).then((isValid) => {
      if (!isValid) {
        setLoading(false);
      }
    });
  }, [redemptionCode, isValidRedemptionCode]);

  if (loading) {
    return <LoadingSpinner message="Validating redemption code" />;
  }

  if (invalidCodeMessage) {
    return (
      <div className="m-8 overflow-visible rounded-3xl bg-white">
        <div className="flex flex-col">
          <div className="flex flex-col content-center justify-center gap-1 p-10 pb-2">
            <p className="text-caringwireDarkBlue text-center text-xl font-light">
              {invalidCodeMessage}
            </p>
          </div>
        </div>
      </div>
    );
  }

  const options = [
    {
      value: "M",
      label: "Male",
    },
    {
      value: "F",
      label: "Female",
    },
    {
      value: "NB",
      label: "Non-Binary",
    },
    {
      value: "U",
      label: "Prefer Not to Say",
    },
  ];

  return (
    <Form
      initialValues={state}
      onSubmit={async (values: FormValues) => {
        // only keep the digits and remove whitespace
        const phoneNumber = `+${values.phoneNumber.replace(/\D/g, "")}`;
        const params = {
          ...values,
          phoneNumber,
          redemptionCode,
        };

        // Form submission logic here
        mixpanel.track("wellabe-p2p-form-page-viewed", {
          "form-name": "info",
        });
        mixpanel.track("wellabe-p2p-form-started", params);

        navigate(`/wellabep2p/dob?redemption_code=${redemptionCode}`, {
          state: params,
        });
      }}
      validate={(values: FormValues) => {
        const errors: Partial<FormValues> = {};

        const firstNameError = required(values.firstName);
        errors.firstName = firstNameError;

        const lastNameError = required(values.lastName);
        errors.lastName = lastNameError;

        const phoneNumberError = required(values.phoneNumber);
        errors.phoneNumber = phoneNumberError;

        const emailError = required(values.email) || email(values.email);
        errors.email = emailError;

        return errors;
      }}
      render={({ handleSubmit, submitError, valid, form }) => (
        <div className="m-8 overflow-visible rounded-3xl bg-white">
          <div className="flex flex-col">
            <div className="flex flex-col content-center justify-center gap-1 p-10 pb-2">
              <h1 className="text-caringwireDarkBlue mb-2 text-center text-3xl font-bold">
                Register for Wellabe P2P
              </h1>
              <p className="text-caringwireDarkBlue text-center text-xl font-light">
                The following information is used to setup your Wellabe P2P
                account and provide access.
              </p>
            </div>
            <div className="p-8">
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <InputField
                  name="firstName"
                  title="First Name"
                  placeholder="First Name *"
                  type="text"
                  autoComplete="given-name"
                  required
                />
                <InputField
                  name="lastName"
                  title="Last Name"
                  placeholder="Last Name *"
                  type="text"
                  autoComplete="family-name"
                  required
                />
                <PhoneNumberInput
                  name="phoneNumber"
                  title="Phone number"
                  required
                />
                <InputField
                  name="email"
                  title="Email"
                  placeholder="Email *"
                  type="email"
                  autoComplete="email"
                  required
                />
                <Select
                  name="gender"
                  placeholder="Gender"
                  options={options}
                  className="react-select-container min-w-40 max-w-64 form-input focus:border-mustard text-caringwire rounded-md bg-transparent text-2xl font-thin transition-colors duration-200 ease-in-out focus:outline-none"
                  required
                  onChange={(option) => {
                    form.change("gender", option.value);
                  }}
                />

                {submitError && (
                  <div className="text-red-500">{FORM_ERROR}</div>
                )}
                <div className="mt-4 flex items-center justify-start">
                  <button
                    type="submit"
                    className={clsx(
                      "bg-caringwire inline-flex w-20 items-center justify-center px-4 py-2 font-bold text-white hover:bg-blue-700",
                      !valid && "opacity-20"
                    )}
                    disabled={!valid}
                  >
                    <span>OK</span>
                  </button>
                  <span
                    className={clsx("ml-2 text-xs", !valid && "opacity-20")}
                  >
                    ⏎ press <span className="font-bold">Enter</span>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default InfoForm;
