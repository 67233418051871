import { PhoneNumberUtil } from "google-libphonenumber";

export const required = (value: string) =>
  value ? undefined : "Please fill this in";

export const email = (value: string) =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ? undefined
    : "Invalid email";

export const isValidNumber = (number, countryCode) => {
  try {
    var phoneUtil = PhoneNumberUtil.getInstance();
    var numberObj = phoneUtil.parseAndKeepRawInput(number, countryCode);
    return phoneUtil.isValidNumber(numberObj);
  } catch (e) {
    return false;
  }
};

export const month = (value: string) => {
  if (!value) return "Please fill this in";

  return /^(0[1-9]|1[0-2])$/.test(value)
    ? undefined
    : "Month must be a number between 01 and 12";
}

export const day = (value: string, monthValue: string, yearValue: string) => {
  if (!value) return "Please fill this in";

  const dayNum = parseInt(value, 10);
  if (dayNum < 1 || dayNum > 31) {
    return "Day must be a number between 01 and 31";
  }

  // Validate day based on day's upper bound only if both month and year are valid, else don't handle it
  const monthCheckError = month(monthValue);
  const yearCheckError = year(yearValue);

  if (monthCheckError || yearCheckError) { return undefined; }

  const monthNum = parseInt(monthValue, 10);
  const yearNum = parseInt(yearValue, 10);

  let maxDaysInMonth = 31;

  if (monthNum === 2) {
    const isLeapYear = (yearNum % 4 === 0 && yearNum % 100 !== 0) || (yearNum % 100 === 0 && yearNum % 400 == 0)
    maxDaysInMonth = isLeapYear ? 29 : 28;
  } else if ([4, 6, 9, 11].includes(monthNum)) {
    maxDaysInMonth = 30;
  }

  if (dayNum > maxDaysInMonth) {
    return `Day must be a number between 01 and ${maxDaysInMonth} for month ${monthValue}`;
  }

  return undefined;
};

export const year = (value: string) => {
  if (!value || !/^\d{4}$/.test(value)) {
    return "Year must be a 4-digit number";
  }

  const currentYear = new Date().getFullYear();
  const valueNum = parseInt(value, 10);
  return (valueNum >= 1900 && valueNum <= currentYear) 
    ? undefined 
    : `Year must be between 1900 and ${currentYear}`;
};

export const zip = (value: string) => {
  if (!value) return "Please fill this in";

  const valueNum = parseInt(value, 10);
  return (valueNum >= 501 && valueNum <= 99500) 
    ? undefined 
    : "ZIP code must be between 00501 and 99500";
}