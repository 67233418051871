import React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import HAHSAddressForm from "components/hahs/AddressForm";
import HAHSInfoForm from "components/hahs/InfoForm";
import HAHSDateOfBirthForm from "components/hahs/DateOfBirthForm";
import HAHSPaymentForm from "components/hahs/PaymentForm";
import HAHSSuccessPage from "components/hahs/Success";
import WellabeBHForm from "components/wellabebh/InfoForm";
import WellabeBHDOB from "components/wellabebh/DateOfBirthForm";
import WellabeBHAddress from "components/wellabebh/AddressForm";
import WellabeBHSuccess from "components/wellabebh/Success";
import WellabeP2PForm from "components/wellabep2p/InfoForm";
import WellabeP2PDOB from "components/wellabep2p/DateOfBirthForm";
import WellabeP2PAddress from "components/wellabep2p/AddressForm";
import WellabeP2PSuccess from "components/wellabep2p/Success";
import { ToastContainer } from "react-toastify";

export function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="hahs" element={<Layout />}>
            <Route index element={<HAHSInfoForm />} />
            <Route path="dob" element={<HAHSDateOfBirthForm />} />
            <Route path="address" element={<HAHSAddressForm />} />
            <Route path="success" element={<HAHSSuccessPage />} />
            <Route path="payment" element={<HAHSPaymentForm />} />
          </Route>
          <Route path="wellabep2p" element={<Layout />}>
            <Route index element={<WellabeP2PForm />} />
            <Route path="dob" element={<WellabeP2PDOB />} />
            <Route path="address" element={<WellabeP2PAddress />} />
            <Route path="success" element={<WellabeP2PSuccess />} />
          </Route>
          <Route path="wellabebh" element={<Layout />}>
            <Route index element={<WellabeBHForm />} />
            <Route path="dob" element={<WellabeBHDOB />} />
            <Route path="address" element={<WellabeBHAddress />} />
            <Route path="success" element={<WellabeBHSuccess />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}
function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page </Link>
      </p>
    </div>
  );
}
