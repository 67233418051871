import React from "react";

const LoadingSpinner: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="flex flex-col items-center space-y-4">
        <div className="size-12 animate-spin rounded-full border-4 border-slate-50 border-t-transparent"></div>
        <p className="text-lg font-semibold text-slate-50">{message}</p>
      </div>
    </div>
  );
};

export default LoadingSpinner;