import { Form } from "react-final-form";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

import React, { useState, useEffect } from "react";

import { FORM_ERROR } from "final-form";
import InputField from "components/form/TextInput";
import { required, zip } from "utils/validations";
import clsx from "clsx";

import Select from "react-select";

import optionsJSON from "utils/us-states.json";
import { mixpanel } from "utils/mixpanel";
import { toast } from "react-toastify";

interface FormValues {
  address: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

const AddressForm: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const options = optionsJSON.options;

  const params = new URLSearchParams(searchParams);
  const redemptionCode = params.get("redemption_code");

  const [hasRedemptionCode, setHasRedemptionCode] = useState(true);

  useEffect(() => {
    if (!redemptionCode) {
      setHasRedemptionCode(false);
      toast.error("Redemption code not found");
    } else {
      setHasRedemptionCode(true);
    }
  }, [redemptionCode]);

  mixpanel.track("wellabe-p2p-form-page-viewed", {
    "form-name": "address",
  });

  return (
    <Form
      initialValues={state}
      onSubmit={async (values: FormValues) => {
        // only keep the digits and remove whitespace
        const params = {
          ...state,
          ...values,
          redemptionCode,
        };

        try {
          const result = await fetch(
            process.env.CORE_API_URL + "/users/create-wellabe-user",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(params),
            }
          );

          const resultJSON = await result.json();
          if (resultJSON.error) {
            toast.error(resultJSON.message);
          } else if (resultJSON.success) {
            window.open("https://kindlyhuman.io/welcome");
            navigate("/wellabep2p/success", { state: params });
          }
        } catch (e) {
          console.error(e);
        }
      }}
      validate={(values: FormValues) => {
        const errors: Partial<FormValues> = {};

        const addressError = required(values.address);
        errors.address = addressError;

        const cityError = required(values.city);
        errors.city = cityError;

        const stateError = required(values.state);
        errors.state = stateError;

        const zipError = zip(values.zip);
        errors.zip = zipError;

        return errors;
      }}
      render={({ handleSubmit, submitError, valid, form }) => (
        <div className="m-8 overflow-visible rounded-3xl bg-white">
          <div className="flex flex-col">
            <div className="flex flex-col content-center justify-center gap-1 p-10">
              <h1 className="text-caringwireDarkBlue mb-2 text-center text-3xl font-bold">
                Home Address
              </h1>
              <p className="text-caringwireDarkBlue mb-2 text-center text-xl font-light">
                Your address is used to connect you with clinicians who are
                licensed in your state. Your personal information is never sold
                or shared.
              </p>
            </div>
            <div className="p-8">
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <InputField
                  name="address"
                  title="Address"
                  placeholder="Address *"
                  autoComplete="street-address"
                  required
                />

                <InputField
                  name="addressLine2"
                  title="Address Line 2"
                  placeholder="Address Line 2"
                  autoComplete="address-line2"
                />
                <InputField
                  name="city"
                  title="City"
                  placeholder="City"
                  autoComplete="address-level2"
                  required
                />
                <Select
                  name="state"
                  placeholder="State"
                  options={options}
                  className="react-select-container min-w-40 max-w-64 form-input focus:border-mustard text-caringwire rounded-md bg-transparent text-2xl font-thin transition-colors duration-200 ease-in-out focus:outline-none"
                  required
                  onChange={(option) => {
                    form.change("state", option.value);
                  }}
                />
                <InputField
                  name="zip"
                  title="Zip"
                  placeholder="Zip"
                  autoComplete="postal-code"
                  required
                />

                {submitError && (
                  <div className="text-red-500">{FORM_ERROR}</div>
                )}
                <div className="mt-4 flex items-center justify-start">
                  <button
                    onClick={() => {
                      // print all the values
                      console.log(state);
                      if (redemptionCode) {
                        navigate(
                          `/wellabep2p/dob?redemption_code=${redemptionCode}`,
                          {
                            state: {
                              ...state,
                              country: "United States",
                            },
                          }
                        );
                      } else {
                        navigate(`/wellabep2p/dob`, {
                          state: {
                            ...state,
                            country: "United States",
                          },
                        });
                      }
                    }}
                    className="bg-caringwire mr-8 inline-flex items-center px-4 py-2 font-bold text-white hover:bg-blue-700"
                  >
                    <span>←</span>
                  </button>
                  <button
                    type="submit"
                    className={clsx(
                      "bg-caringwire inline-flex w-20 items-center justify-center px-4 py-2 font-bold text-white hover:bg-blue-700",
                      (!valid || !hasRedemptionCode) && "opacity-20"
                    )}
                  >
                    <span>OK</span>
                  </button>
                  <span
                    className={clsx(
                      "ml-2 text-xs",
                      (!valid || !hasRedemptionCode) && "opacity-20"
                    )}
                  >
                    ⏎ press <span className="font-bold">Enter</span>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default AddressForm;
