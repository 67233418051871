import React from "react";
import { mixpanel } from "utils/mixpanel";

const SuccessPage: React.FC = () => {
  mixpanel.track("wellabe-bh-form-page-viewed", {
    "form-name": "success",
  });

  return (
    <div className="m-8 overflow-clip rounded-3xl bg-white">
      <div className="flex flex-col">
        <div className="flex flex-col content-center justify-center gap-1 bg-white p-10">
          <h1 className="text-caringwireDarkBlue mb-2 text-center text-3xl font-bold">
            Success{" "}
          </h1>
          <p className="text-caringwireDarkBlue mb-2 text-center text-xl font-light">
            Your information has been successfully submitted. We will contact
            you shortly!
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
